import React from 'react';
import styled from 'styled-components';
import * as Ant from 'antd';
import {AddBox} from '@styled-icons/material/AddBox';
import {IndeterminateCheckBox} from '@styled-icons/material-rounded/IndeterminateCheckBox';
import {ArrowUpward} from '@styled-icons/material-rounded/ArrowUpward';
import {ArrowDownward} from '@styled-icons/material-rounded/ArrowDownward';

export default function AdminArrayTable(props) {
  const {
    columns = [],
    dataSource = [],
    onChange,
    defaultRecord,
    enableAdd = true,
    enableDelete = true,
    enableSort = true,
    keyExtractor,
  } = props;

  return (
    <Wrapper>
      <Ant.Row>
        {columns.map((col, idx) => (
          <Ant.Col span={col.span} className="label" key={col.key || idx}>
            {col.title}
          </Ant.Col>
        ))}
        {(enableSort || enableDelete) && (
          <Ant.Col span={2} className="label">
            編輯
          </Ant.Col>
        )}
      </Ant.Row>
      {dataSource.map((record, idx) => (
        <Ant.Row
          // key={crypto.randomUUID()}
          key={typeof keyExtractor === 'function' ? keyExtractor(record) : idx}>
          {columns.map((col, colIdx) => (
            <Ant.Col span={col.span} key={col.key || colIdx}>
              {typeof col.render === 'function'
                ? col.render(record[col.dataIndex], record, idx)
                : record[col.dataIndex]}
            </Ant.Col>
          ))}

          {(enableDelete || enableSort) && (
            <Ant.Col span={2}>
              {enableSort && idx !== 0 && (
                <ArrowUpward
                  size={30}
                  onClick={() => {
                    if (idx - 1 >= 0) {
                      let next = [...(dataSource || [])];
                      let sliced = next.splice(idx, 1)?.[0];
                      next.splice(idx - 1, 0, sliced);
                      onChange(next);
                    }
                  }}
                />
              )}
              {enableSort && idx < dataSource.length - 1 && (
                <ArrowDownward
                  size={30}
                  onClick={() => {
                    if (idx + 1 <= dataSource.length - 1) {
                      let next = [...(dataSource || [])];
                      let sliced = next.splice(idx, 1)?.[0];
                      next.splice(idx + 1, 0, sliced);
                      onChange(next);
                    }
                  }}
                />
              )}
              {enableDelete && (
                <IndeterminateCheckBox
                  size={30}
                  onClick={() => {
                    let next = [...(dataSource || [])];
                    next.splice(idx, 1);
                    onChange(next);
                  }}
                />
              )}
            </Ant.Col>
          )}
        </Ant.Row>
      ))}

      {enableAdd && (
        <Ant.Row>
          <Ant.Col span={22}></Ant.Col>

          <Ant.Col span={2}>
            <AddBox
              size={30}
              onClick={() => {
                let next = [...(dataSource || [])];
                if (
                  typeof defaultRecord === 'object' &&
                  !Array.isArray(defaultRecord) &&
                  defaultRecord !== null
                ) {
                  next.push({...defaultRecord});
                } else {
                  next.push(defaultRecord);
                }
                onChange(next);
              }}
            />
          </Ant.Col>
        </Ant.Row>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .ant-row {
    border-left: solid 1px #ccc;
    border-top: solid 1px #ccc;
  }
  .ant-row + .ant-row {
    border-top: 0;
  }

  .ant-col {
    padding: 6px;
    display: flex;
    align-items: center;
    border-right: solid 1px #ccc;
    border-bottom: solid 1px #ccc;
  }

  .label {
    background-color: #ffe6a1;
  }
`;
